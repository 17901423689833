<template>
	<!-- 新增/编辑购物卡 -->
	<div class="adh-card-edit el-content">
		<a-form :label-col="{ span: 2 }" :wrapper-col="{ span: 16 }">
			<a-form-item label="礼品卡生成方式">
				<a-radio-group v-model:value="form.insert_type">
				    <a-radio :value="1">自动生成卡号和密码</a-radio>
				    <a-radio :value="2">导入卡号和密码</a-radio>
				</a-radio-group>
			</a-form-item>
			<a-form-item v-if="form.insert_type==1">
				<template #label>
					<span>生成数量</span>
					<a-tooltip title="生成数量只对礼品类型为商品和认养起用,土地类型一次只能生成一张礼品卡">
						<i class="ri-information-fill"></i>
					</a-tooltip>
				</template>
				<a-input v-model:value="form.count" type="number"></a-input>
			</a-form-item>
			<a-form-item label="导入礼品卡" v-if="form.insert_type==2">
				{{form.excel_url ? '已上传':""}}
				<com-upload-btn 
					:customUpload="true" 
					btn-text="点击上传"
					@uploadChange="e=>form.excel_url = e">
				</com-upload-btn>
				<a href="https://kundian.cqkundian.com/b7027202006021550085403.xlsx">下载模板</a>
			</a-form-item>
			<a-form-item label="标题" required>
				<a-input v-model:value="form.title"></a-input>
			</a-form-item>
			<a-form-item label="封面">
				<kd-img-select :src="form.cover" @success="(url)=>{ form.cover = url }"></kd-img-select>
			</a-form-item>
			<a-form-item label="说明" required>
				<a-input v-model:value="form.summary"></a-input>
			</a-form-item>
			<a-form-item label="面值" required>
				<a-input v-model:value="form.price"></a-input>
			</a-form-item>
			<a-form-item label="兑换时间">
				<a-range-picker v-model:value="time" />
			</a-form-item>
			<a-form-item label="礼品信息">
				<div class="adh-card-edit-gift" v-for="(item,index) in gift" :key="index">
					<div>
						<span>礼品类型</span>
						<a-radio-group v-model:value="item.goods_type">
						    <a-radio :value="1">商品</a-radio>
						    <a-radio :value="2">
								<a-tooltip title="选择土地后不能批量生成">
									<span>土地</span>
								</a-tooltip>
							</a-radio>
						    <a-radio :value="3">认养</a-radio>
						    <a-radio :value="4">兑换余额</a-radio>
						</a-radio-group>
					</div>
					
					<!-- 选择商品信息 -->
					<div v-if="item.goods_type == 1">
						<a-form-item label="选择商品">
							<a-select
								style="width: 300px;"
								show-search
								v-model:value="item.goods_id"
								:defaultActiveFirstOption="false"
								placeholder="请输入商品名称"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="null"
								@search="searchGoods"
							>
								<a-select-option v-for="(item, index) in goods.list" :key="index" :value="item.id">{{ item.goods_name }}</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item label="选择规格" v-if="goods.spec && goods.spec.length > 0">
							<a-radio-group v-model:value="item.spec_id">
								<a-radio :value="val.id" v-for="val in goods.spec" :key="val.id">
									<span v-for="spec in val.sku" :key="spec.id">{{spec.spec_value}};</span>
								</a-radio>
							</a-radio-group>
						</a-form-item>
						
						<a-form-item label="礼品数量" v-if="goods.spec">
							<a-input v-model:value="item.goods_num" type="number"></a-input>
						</a-form-item>
						<a-form-item label="包装信息">
							<a-select v-model:value="item.package" mode="multiple" style="width: 300px;">
								<a-select-option
									v-for="(val,ind) in packageList"
									:key="ind"
									:value="val.id">{{val.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</div>
					
					<!-- 选择土地 -->
					<div v-if="item.goods_type==2">
						<a-form-item label="选择土地">
							<a-select
								style="width: 300px;"
								show-search
								v-model:value="item.goods_id"
								:defaultActiveFirstOption="false"
								placeholder="请输入商品名称搜索查找"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="null"
								@search="searchLand"
								@change="changeLand"
							>
								<a-select-option v-for="(item, index) in land.list" :key="index" :value="item.id">
									{{ item.land_name }}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="选择地块" v-if="land.detail" >
							<a-select v-model:value="item.spec_id" style="width: 300px;" mode="multiple">
								<a-select-option 
									v-for="(val,ind) in land.detail.spec" 
									:key="ind" 
									:value="val.id">{{val.alias+'('+val.land_num+')'+'('+val.area+' ㎡)'}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="选择服务" v-if="land.detail">
							<a-select v-model:value="item.land_ser_id" style="width: 300px;">
								<a-select-option 
									v-for="(val,ind) in land.detail.ser" 
									:key="ind" 
									:value="val.id">{{val.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="选择期限" v-if="land.detail">
							<a-select v-model:value="item.land_day" style="width: 300px;">
								<a-select-option 
									v-for="(val,ind) in land.detail.land.lease_cycle" 
									:key="ind" 
									:value="val.day">{{val.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						
						<a-form-item v-if="land.detail" label="选择种子">
							<div class="land-seed-select">
								<div class="land-seed-select-item" v-for="(val,ind) in item.select_seed" :key="ind">
									<a-input type="number" v-model:value="val.num" :addon-before="val.send_name" addon-after="㎡"></a-input>
								</div>
							</div>
							<div style="color: #999;font-size: 12px;">提示：选择种子请不要超出地块种植面积哦~</div>
						</a-form-item>
						<a-form-item label="包装信息">
							<a-select v-model:value="item.package" mode="multiple" style="width: 300px;">
								<a-select-option
									v-for="(val,ind) in packageList"
									:key="ind"
									:value="val.id">{{val.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
						
					</div>
					
					<!-- 选择认养 -->
					<div v-if="item.goods_type == 3">
						<a-form-item label="选择认养">
							<a-select
								style="width: 300px;"
								show-search
								v-model:value="item.goods_id"
								:defaultActiveFirstOption="false"
								placeholder="请输入商品名称搜索查找"
								:show-arrow="false"
								:filter-option="false"
								:not-found-content="null"
								@search="searchAdopt"
								@change="changeAdopt"
							>
								<a-select-option v-for="(item, index) in adopt.list" :key="index" :value="item.id">
									{{item.name+'第'+item.numbers+'期'}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="选择周期" v-if="adopt.detail && adopt.detail.cycle.length > 0">
							<a-select v-model="item.cycle_id"  style="width: 300px;">
								<a-select-option 
									v-for="(val,ind) in adopt.detail.cycle" 
									:key="ind" 
									:value="val.id">
									{{val.alias}}
								</a-select-option>
							</a-select>
						</a-form-item>
						<a-form-item label="礼品数量" v-if="goods.spec">
							<a-input v-model:value="item.goods_num" type="number"></a-input>
						</a-form-item>
						
						<a-form-item label="包装信息">
							<a-select v-model:value="item.package" mode="multiple" style="width: 300px;">
								<a-select-option
									v-for="(val,ind) in packageList"
									:key="ind"
									:value="val.id">{{val.name}}
								</a-select-option>
							</a-select>
						</a-form-item>
					</div>
					
					<!-- 选择余额 -->
					<div v-if="item.goods_type == 4">
						<a-form-item label="余额面值">
							<a-input type="number" v-model:value="item.exchange_amount"></a-input>
						</a-form-item>
					</div>
				</div>
			</a-form-item>
			<a-form-item  :wrapper-col="{ offset: 2 }">
				<a-space>
					<a-button type="primary" @click="saveForm">保存</a-button>
					<a-button @click="$router.go(-1)">返回</a-button>
				</a-space>
			</a-form-item>
		</a-form>
	</div>
</template>

<script>
import comUploadBtn from "@/components/public/com-upload-btn.vue"
import { reactive, toRefs } from 'vue'
import tool from '@/util/tool'
import cardModel from '@/api/addons/card'
import router from '@/router'
import goodsModel from '@/api/goods'
import landModel from '@/api/land'
import adoptModel from '@/api/adopt'
export default{
	components:{
		comUploadBtn
	},
	setup(){
		const state = reactive({
			form:{
				cid:0,
				count:1,
				title:'',
				price:'',
				summary:'',
				end_time:'',
				start_time:'',
				goods:null,
				cover:'',
				insert_type:1,
				excel_url:''
			},
			time:[],
			gift:[
				{
					goods_type:1,  //礼品类型 1商城 2土地 3认养
					goods_id:'',
					spec_id:[],		//商品规格id
					goods_num:1,
					package:[],
					select_seed:[],
					cycle_id:'',
					land_ser_id:'',
					land_day:'',
					land_seed:{},
					exchange_amount:0,
				}
			],
			packageList:[],
			goods:{	//商品信息
				list:[],
				detail:null,
				spec:[],	//商品的规格id
			},
			land:{
				list:[],
				detail:null,
				seed:[],
			},
			adopt:{
				list:[],
				detail:null
			},
		})

		cardModel.getCardPackage(1,999,res=>{
			state.packageList = res.list
			if( state.packageList.length ==0 ){
				tool.confirm("您当前还没有添加包装信息，请先填写包装信息后再来添加礼品卡吧！").then(()=>{
					router.push("/adhibition/card/package")
				}).catch(()=>router.go(-1))
			}
		})

		function saveForm(){
			let form =JSON.parse(JSON.stringify(state.form)) , { gift ,time } = state
			if( time.length == 0 ) {
				tool.message("请选择礼品卡兑换时间","warning")
				return
			}

			[form.start_time,form.end_time ] = time
			form.goods = gift

			//判断提交信息中是否存在礼品类型为土地的
			let is_has_land = false
			
			gift.forEach(item=>{
				form.goods_type = item.goods_type
				if( item.goods_type == 2 ){
					is_has_land = true
					item.land_seed = {}
					item.select_seed.forEach(val=>{
						if( val.num >= 1 ){
							item.land_seed[val.id] = val.num
						}
					})
				}
			})
			if( is_has_land ) form.count = 1
			cardModel.addCard(form)
		}

		function searchGoods(e){
			goodsModel.getGoodsList(1,999,{key:e},res=>{
				state.goods.list = res.list
				state.spec = []
			})
		}

		function searchLand(e){
			landModel.getLandList(1,999,{key:e},res=>state.land.list = res.list)
		}
		function changeLand(e){
			if( state.land.seed.length == 0 ){
				landModel.getSeed(1,999,null,res=>{
					state.land.seed = res.list
					state.land.seed.forEach(item=>item.num = 0)
					state.gift[0].select_seed = JSON.parse(JSON.stringify(state.land.seed))
				})	
			}
			landModel.getLandDetail({id:e},res=>state.land.detail = res)			
		}

		function searchAdopt(e){
			adoptModel.getAdoptList(1,999,{key:e,is_chil:1},res=>state.adopt.list = res.list)
		}

		function changeAdopt(e){
			adoptModel.getAdoptDetail(e,null,res=>state.adopt.detail = res)
		}

		return{
			...toRefs(state),
			saveForm,
			searchGoods,
			searchLand,
			changeLand,
			searchAdopt,
			changeAdopt
		}
	}
}
</script>

<style lang="scss">
	.adh-card-edit{
		&-gift{
			width: 68%;
			border: 1px solid #dcdfe6;
			font-size: 12px;
			color: #999;
			padding: 10px 1%;
			margin-bottom: 10px;
			
			>div{
				line-height: 40px;
			}
			
			.aceg-opera{
				width: 30px;
				height: 30px;
				color: #fff;
				text-align: center;
				line-height: 30px;
				font-size: 20px;
				position: absolute;
				margin-top: -20px;
				border-radius: 5px;
				cursor: pointer;
			}
			.aceg-add{
				background: #409eff;
				margin-left: 73%;
			}
			.aceg-del{
				background: #ff5500;
				margin-left: 70%;
			}
		}
	}

	.land-seed-select{
		display: flex;
		flex-wrap: wrap;
		
		&-item{
			width: 200px;
			margin-right: 16px;
			.el-input-group{
				width: 100% !important;
			}
			.el-input-group__prepend{
				line-height: 26px;
			}
		}
	}
</style>
